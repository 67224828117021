import React from 'react'
import './Card.css'

export const Card = () => {
    return (
        <div className='card'>
            <img className='image_project' src='https://wpblog.zyro.com/wp-content/uploads/2020/09/nathalie-jankie-portfolio-website.png' alt='project' />
        </div>
    )
}

import React, {useState, useEffect} from 'react'
import { Card } from '../../Ui/Card/Card';
import './Projects.css'

export const Projects = () => {
    return (
       <section>
           <div className='center_section_projects'>
                <Card /> 
           </div> 
       </section>
    )
};
